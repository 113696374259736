<template>
<div class="sidebar">
    <div class="sidebar-wrapper">
        <div class="mx-2 d-flex logo">
            <router-link class="logo-link" tag="div" to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="65" height="36" viewBox="0 0 90 40">
                <g fill="#ffffff" fill-rule="evenodd">
                <path
                    d="M19.307 25.022h-3.928c-5.794 0-6.874 2.346-6.874 4.497 0 2.188 1.312 4.077 4.542 4.077 5.067 0 6.26-3.355 6.26-7.898v-.676zm8.034 6.09c0 2.173 1.19 6.047 1.495 8.329h-8.48c-.243-.961-.438-2.897-.492-3.888C18.52 38.164 15.488 40 10.82 40 2.83 40 0 35.04 0 30.183c0-5.788 4.028-10.558 15.228-10.558h3.796v-2.338c0-2.629-.818-4.668-4.61-4.668-3.751 0-4.657 1.908-4.96 4.273H1.249c.343-5.076 3.4-10.225 13.3-10.225 7.63 0 12.793 2.616 12.793 10.574v13.87zM32.955 39.333L41.194 39.333 41.194 11.333 32.955 11.333zM46.812 19.481c0-5.32-.007-8.739-.125-10.967h8.323c.173 1.4.228 3.457.228 5.68 1.097-2.719 3.95-6.18 7.926-6.194v8.595c-4.782-.105-7.808 2.15-7.808 9.586V39.333h-8.544V19.481zM82.47 25.022h-3.929c-5.792 0-6.874 2.346-6.874 4.497 0 2.188 1.315 4.077 4.544 4.077 5.066 0 6.26-3.355 6.26-7.898v-.676zm8.035 6.09c0 2.173 1.19 6.047 1.495 8.329h-8.482c-.24-.961-.435-2.897-.49-3.888C81.684 38.164 78.652 40 73.983 40c-7.99 0-10.819-4.96-10.819-9.817 0-5.788 4.027-10.558 15.228-10.558h3.796v-2.338c0-2.629-.82-4.668-4.61-4.668-3.751 0-4.657 1.908-4.959 4.273h-8.207c.343-5.076 3.4-10.225 13.3-10.225 7.63 0 12.793 2.616 12.793 10.574v13.87zM32.955 8L41.194 8 41.194 0 32.955 0z" />
                </g>
            </svg>
            <span>城智科技</span>
            </router-link>
        </div>

        <ul class="nav sidebar_nav">
            <router-link 
                :class="{'active': this.$route.meta.sidebarCategory === 'event'}"
                tag="li"
                to="/admin">
                <b-icon-list-ul class="icon"></b-icon-list-ul>
                <span>活動列表</span>
            </router-link>
            <router-link 
                :class="{'active': this.$route.meta.sidebarCategory === 'category'}"
                tag="li"
                to="/admin/getCategories">
                <b-icon-bookmarks class="icon"></b-icon-bookmarks>
                <span>活動分類</span>
            </router-link>
        </ul>
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {

        }
    },

    created() {},

    computed: {}
}

</script>
<style lang="scss" scoped>
.sidebar {
    overflow: auto;
    max-height: 100%;
    height: 100%;
    transition-property: top,bottom;
    transition-duration: .2s,.2s;
    transition-timing-function: linear,linear;
    -webkit-overflow-scrolling: touch
}

.sidebar .sidebar-wrapper, body>.navbar-collapse .sidebar-wrapper {
    position: relative;
    max-height: calc(100vh - 75px);
    min-height: 100%;
    overflow: auto;
    width: 260px;
    z-index: 4;
    padding-bottom: 100px;
}

.sidebar .sidebar-wrapper {
    display: flex;
    flex-direction: column;
    background: #334a67;
    color: hsla(0,0%,100%,.8);
}
.sidebar .nav-main__links {
    flex: 1;
}

.logo {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding:5px 5px;
    margin-bottom: 15px;
    justify-content: center;
}

.logo-link{
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 24px;
    align-items: center !important;
    font-weight: 500;
    color: #fff;
    span {
        display:inline-block;
        padding-left: 6px;
        padding-top: 5px;
    }
}

.sidebar_nav{
    display: block;
    padding-left: 0;
    list-style: none;
    li {
        transition: background ease 0.25s;
        opacity: .86;
        color: #fff;
        cursor: pointer;
        display: block;
        padding: 10px 20px;
        .icon {
            margin-right: 20px;
        }
    }

    li.active{
        background: rgba(10,9,9, 0.3);
    }

    li:hover:not(.active) {
        background: rgba(208, 208, 208, 0.3);
    }
}

</style>
