<template>
    <nav class="navbar navbar-expand-lg">
        <b-container fluid>
            <div class="ml-auto" role="group">
                <button @click="logout" class="btn btn-primary">
                    {{ $t('Logout') }}
                </button>
            </div>
        </b-container>
    </nav>
</template>
<script>
import { getLogout } from '@/apis/apiAuth';

export default {
    computed: {},

    data () {
        return {}
    },

    methods: {
        async logout() {
            await getLogout();

            this.$router.push({
                path: '/'
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar {
    border: 0;
    font-size: 16px;
    border-radius: 0;
    min-height: 50px;
    background-color: hsla(0,0%,100%,.96);
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 5px 15px;
}
</style>
