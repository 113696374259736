<template>
    <div class="wrapper">
        <admin-side-bar />
        <div class="main-panel">
        <admin-top-navbar />
        <div class="content">
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
        </div>
    </div>
</template>
<style lang="scss">

</style>
<script>
import AdminTopNavbar from '@/components/admin/AdminTopNavbar.vue';
import AdminSideBar from '@/components/admin/AdminSideBar.vue';

export default {
    components: {
        AdminTopNavbar,
        AdminSideBar,
    },

    methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper {
    position: relative;
    top: 0;
    height: 100vh;
    display: flex;
}

.main-panel {
    overflow: auto;
    max-height: 100%;
    height: 100%;
    transition-property: top,bottom;
    transition-duration: .2s,.2s;
    transition-timing-function: linear,linear;
    -webkit-overflow-scrolling: touch;
    background: hsla(240,7%,81%,.15);
    position: relative;
    float: right;
    width: calc(100% - 260px);
    min-height: 100%
}

.content {
    padding: 30px 15px;
    min-height: calc(100% - 123px)
}

.fade-enter-active,
  .fade-leave-active {
    transition: opacity .1s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }
  
</style>